import axios from "axios"

const levelLoader = async () => {
  let savedData = false
  if (typeof window !== "undefined") {
    savedData = localStorage.getItem("levels")
  }
  //TODO: Add date checker (expires after 1 week)

  // if (!savedData) {
  const response = await axios.get(
    `${process.env.GATSBY_APP_SERVICE_URL}/wp-json/wp/v2/sb_level`
  )
  if (!response.status === 200) {
    console.warn(response?.statusText)
    return "error"
  } else {
    const jsonObject = await response.data
    if (typeof window !== "undefined") {
      localStorage.setItem("levels", JSON.stringify(jsonObject))
      localStorage.setItem("levelsDate", new Date())
    }
    return jsonObject
  }
  // } else {
  //   return JSON.parse(savedData)
  // }
}

export default levelLoader
