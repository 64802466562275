import axios from "axios"

const serviceLoader = async () => {
  let savedData = false

  if (typeof window !== "undefined") {
    savedData = localStorage.getItem("services")
  }
  //TODO: Add date checker (expires after 1 week)

  //TODO: READD CONDITION WHEN DATA IS MORE STABLE
  // if (!savedData) {
  const response = await axios.get(
    `${process.env.GATSBY_APP_SERVICE_URL}/wp-json/wp/v2/sb_service`
  )
  if (!response.status === 200) {
    console.warn(response?.statusText)
    return "error"
  } else {
    const jsonObject = await response.data
    if (typeof window !== "undefined") {
      localStorage.setItem("services", JSON.stringify(jsonObject))
      localStorage.setItem("servicesDate", new Date())
    }
    return jsonObject
  }
  // } else {
  //   return JSON.parse(savedData)
  // }
}

export default serviceLoader
