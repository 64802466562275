import React, { useState, createContext, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { WordpressContext } from "./Wordpress"
import frequency from "../helpers/frequency"
import urlEncoder from "../helpers/url-encoder"
const codec = require("json-url")("lzw")


export const ProgressContext = createContext()

// Append to url to test
// const exampleEncoded = "?s=woLCoWzDjgABLMOUwqFzwpLCg8KhacSDxIXDk8KhaALCoWYAxIvEjcSELMORxJEDxJQC"

export const ProgressProvider = ({ children }) => {
  //WORDPRESS CONTEXT
  const { allLevels, allServices } = useContext(WordpressContext)
  const [serviceList] = allServices
  const [levelsList] = allLevels

  // IS AN ARRAY
  const [services, setServices] = useState([])
  // A NUMBER 0 - 5
  const [step, setStep] = useState(1)
  // IS AN OBJECT OR NULL
  const [level, setLevel] = useState()

  const [next, setNext] = useState({
    active: false,
    visible: true,
  })

  const [calculating, setCalculating] = useState(false)

  //Changes to true after first use effect function to init second use effect function
  const [initialLoad, setInitialLoad] = useState(false)

  useEffect(async () => {
    //GET PARAMS FROM URL
    if (typeof window !== "undefined" && serviceList?.[0] && levelsList?.[0]) {
      //GET URL PARMS
      let search = window.location.search
      let params = new URLSearchParams(search)
      let encoded = params.get("s")

      if (encoded) {
        //DECODE URL PARAMS
        const decoded = await codec.decompress(encoded)

        //IF DECODED IS AN OBJECT WITH SERVICE AND LEVEL KEYS
        if (typeof decoded === "object" && decoded.l && decoded.s) {
          //MATCH ALL LEVELS WITH IDS
          const level = levelsList.filter(l => l.id === decoded.l)

          //MATCH ALL SERVICES TO IDS
          let services = []
          if (decoded.s?.[0]) {
            decoded.s.forEach(service => {
              const matched = serviceList.filter(s => s.id === service.i)
              if (matched?.[0]) {
                services.push({
                  ...matched[0],
                  //ADD addional key values for hours and frequency
                  hours: service.h || 0,
                  frequency: frequency?.[service.f]?.value,
                })
              }
            })
          }

          let step = 5
          if (decoded.z) {
            step = decoded.z
          }

          setServices(services)
          setLevel(level[0])
          setStep(step)
        }
      }
      setTimeout(() => {
        setInitialLoad(true)
      }, 40)
    }

    return () => {}
  }, [serviceList, levelsList])

  useEffect(async () => {
    if (initialLoad) {
      //Encode progress and attach to url. Allows to user to refresh and return to same place
      if (typeof window !== undefined) {
        const newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          `?s=${await urlEncoder(services, level, step, true)}`
        window.history.pushState({ path: newurl }, "", newurl)
      }
    }


    if (typeof window.gtag !== 'undefined') {
      window.gtag("event", "click", {
        'event_category':'step',
        'event_label': 'servicebuilder_step' + step.toString()
      });
    }

    if (typeof window.fbq !== 'undefined') {
      window.fbq('track', 'ViewContent', {'content_name':'servicebuilder_step' + step.toString()});
    }

    return () => {}
  }, [services, level, step, initialLoad])

  return (
    <ProgressContext.Provider
      value={{
        currentServices: [services, setServices],
        currentLevel: [level, setLevel],
        currentStep: [step, setStep],
        nextStatus: [next, setNext],
        calculatingServices: [calculating, setCalculating],
      }}
    >
      {children}
    </ProgressContext.Provider>
  )
}

ProgressProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
