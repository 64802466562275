const codec = require("json-url")("lzw")

const frequencyEncode = frequency => {
  if (frequency === "daily") {
    return 0
  } else if (frequency === "weekly") {
    return 1
  } else if (frequency === "fortnightly") {
    return 2
  } else if (frequency === "monthly") {
    return 3
  } else {
    return ""
  }
}

const urlEncoder = async (services, level, step, live) => {
  const progressObject = {
    l: "",
    s: [],
    z: "",
  }

  if (level?.id) {
    progressObject.l = level.id
  }

  if (services?.[0]) {
    services.forEach(service => {
      progressObject.s.push({
        i: service?.id || "",
        h: service?.hours || 0,
        f: frequencyEncode(service?.frequency),
      })
    })
  }

  if (step) {
    progressObject.z = step
  }

  const encoded = await codec.compress(progressObject)

  let siteUrl = "https://hcpcalculator.kincare.com.au"
  if (process.env.GATSBY_ENV === "staging") {
    siteUrl = "https://kincare-servicebuilder.preview.pollen.com.au/"
  } else if (process.env.GATSBY_ENV === "local") {
    siteUrl = "http://localhost:8000"
  }
  if (live) {
    return encoded
  } else {
    return `${siteUrl}?s=${encoded}`
  }
}

export default urlEncoder
