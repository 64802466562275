/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import * as React from "react"
import { WordpressProvider } from "./src/context/Wordpress"
import { ProgressProvider } from "./src/context/Progress"

export const wrapRootElement = ({ element }) => (
  <WordpressProvider>
    <ProgressProvider>{element}</ProgressProvider>
  </WordpressProvider>
)
