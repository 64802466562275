import React, { useState, useEffect, createContext } from "react"
import PropTypes from "prop-types"
import serviceLoader from "../helpers/services"
import levelLoader from "../helpers/levels"
import log from "../helpers/log"

export const WordpressContext = createContext()

export const WordpressProvider = ({ children }) => {
  const [serviceList, setServiceList] = useState()
  const [levelsList, setLevelsList] = useState()
  const [status, setStatus] = useState("loading")

  const fetchServices = async () => {
    try {
      const servicesResponse = await serviceLoader()
      if (servicesResponse === "error") {
        log.error(servicesResponse)
        setStatus("error")
      } else {
        setServiceList(servicesResponse)
      }
    } catch (e) {
      log.error(e)
      setStatus("error")
    }
  }

  const fetchLevels = async () => {
    try {
      let levelsResponse = await levelLoader()
      //SORT LEVELS ALPHABETICALLY (LEVEL - 1, LEVEL - 2 etc.)
      if (levelsResponse === "error") {
        log.error(levelsResponse)
        setStatus("error")
      } else {
        levelsResponse.sort((a, b) => (a.acf?.name > b.acf?.name && 1) || -1)
        setLevelsList(levelsResponse)
      }
    } catch (e) {
      log.error(e)
      setStatus("error")
    }
  }

  useEffect(async () => {
    if (!serviceList) {
      fetchServices()
    }
    if (!levelsList) {
      fetchLevels()
    }
  }, [])

  return (
    <WordpressContext.Provider
      value={{
        allServices: [serviceList, setServiceList],
        allLevels: [levelsList, setLevelsList],
        loading: [status, setStatus],
      }}
    >
      {children}
    </WordpressContext.Provider>
  )
}

WordpressProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
